export default class VersaoService {
  constructor(http) {
    this._http = http
  }

  async getVersao() {
    const { data } = await this._http.get('/api/versao')
    return data
  }
}
