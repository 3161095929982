import { defineStore } from 'pinia'

export const consignatariaStore = defineStore('consignataria', {
  state: () => {
    return {
      consignatariaLogada: {
        id: null,
        razaoSocial: null,
        nomeFantasia: null,
        rubricas: [{}],
        permissoes: [],
      },
      consignatarias: [],
      consignatariaSelecionada: null,
    }
  },
})
