<template>
  <span class="font-medium"
    >{{ nomeEmpresa }} - WEB: {{ versao }} - API: {{ versaoApi }}</span
  >
</template>

<script>
import Config from '@/config'
import VersaoService from '@/service/VersaoService'
export default {
  data() {
    return {
      versaoApi: '',
      nomeEmpresa: Config.NOME_EMPRESA,
    }
  },
  computed: {
    versao: () => {
      return Config.TAG_GIT
    },
    // darkTheme() {
    // 	return this.$appState.darkTheme;
    // }
  },

  created() {
    this.versaoService = new VersaoService(this.$http)
  },

  mounted() {
    this.versaoService.getVersao().then((res) => {
      this.versaoApi = res.versao
    })
  },
}
</script>
