export default class MensagemService {
  constructor(http) {
    this._http = http
  }

  async buscarMensagens(page, size) {
    const response = await this._http.get(
      `/api/mensagens?page=${page - 1}&size=${size}`,
    )
    return response
  }

  // MensagemService.js
  async buscarUltimaMensagem(perfil, consignatariaId) {
    let url = `/api/mensagens/valida?perfil=${encodeURIComponent(perfil)}`
    if (consignatariaId !== undefined && consignatariaId !== null) {
      url += `&consignatariaId=${encodeURIComponent(consignatariaId)}`
    }
    const response = await this._http.get(url)
    return response.data
  }

  // async getListaConfiguracoes() {
  //   const { data } = await this._http.get(`/api/configuracoes/list`)
  //   return data
  // }

  // async getConfiguracoes(dados) {
  //   const { data } = await this._http.get(
  //     `/api/configuracoes/${dados.page}/${dados.rows}`,
  //   )
  //   return data
  // }

  async saveMensagem(mensagem) {
    if (mensagem.id) {
      return await this._http.put('/api/mensagens/', mensagem)
    } else {
      return await this._http.post('/api/mensagens/', mensagem)
    }
  }

  // async deleteConfiguracao(chave) {
  //   await this._http.delete(`/api/configuracoes/${chave}`)
  // }

  // async getConfiguracao(id) {
  //   const { data } = await this._http.get(`/api/configuracoes/${id}`)
  //   return data
  // }
}
